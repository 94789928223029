import React from 'react'
import styled, { ThemeContext } from 'styled-components';
import Img from "gatsby-image/withIEPolyfill"
import useImages from '../hooks/useImages';
import { gatsbyImage, obliqueBorder, displayFlex } from '../styles/mixins';
import { CABINET } from '../reference/anchors';
import { formatString } from '../helpers/selectors';
import Title from './title';
import { media } from '../styles/mediaQueries';

const CabinetStyled = styled.div`
  width: 100%;
  max-width: 100vw;
  flex-shrink: 0;
  ${gatsbyImage({
    width: '80vw',
    maxWidth: '250px',
    height: '300px',
  })}
  overflow: hidden;
  background: ${props => props.theme.color.backgroundColor};
  ${displayFlex({
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  })}

  ${props => media.desktop`
    flex-direction: row;
    ${gatsbyImage({
      width: '40%',
      maxWidth: 'unset',
      height: '100%',
    })}
    .gatsby-image-wrapper {
      flex-shrink: 0;
      ${obliqueBorder({ width: props.theme.width.oblique, height: props.imageHeight, bg: props.theme.color.backgroundColor })}
    }
  `}



`

const Presentation = styled.div`
  padding: 2em 5em;
  font-size: 0.85em;
  max-width: 100vw;
`

const Cabinet = ({ saveImageHeight, saveObliqueAngle, windowWidth }) => {
  const cabinetImage = useImages('portrait')
  const [imageHeight, setImageHeight] = React.useState(500)
  const [needToGetImageHeight, toggleNeedImageHeight] = React.useState(1)
  const imageRef = React.useRef(null);
  const { width: { oblique } } = React.useContext(ThemeContext);
  React.useEffect(() => {
    if (imageRef && imageRef.current && imageRef.current.imageRef && imageRef.current.imageRef.current && needToGetImageHeight) {
      const imageComputedHeight = Math.round(imageRef.current.imageRef.current.getBoundingClientRect().height);
      if (imageComputedHeight !== imageHeight) {
        setImageHeight(imageComputedHeight)
        saveObliqueAngle(Math.asin(oblique / imageComputedHeight))
        saveImageHeight(imageComputedHeight)
      }
      if (needToGetImageHeight !== 0) {
        toggleNeedImageHeight(0)
      }
    } else if (needToGetImageHeight) {
      toggleNeedImageHeight(needToGetImageHeight + 1)
    }
  }, [needToGetImageHeight, imageHeight, saveObliqueAngle, oblique, saveImageHeight])
  return(
    <CabinetStyled
      id={formatString(CABINET)}
      imageHeight={imageHeight}
    >
      <Img
        ref={imageRef}
        fluid={cabinetImage}
        objectFit="cover"
        objectPosition="0% 0%"
        alt="Audrey Diallo-Missoffe"
        title="Audrey Diallo-Missoffe"
      />
      <Presentation>
        <Title>Audrey Diallo-Missoffe</Title>
        <p>
          Diplômée d’un DEA en droit de la santé et après avoir exercé durant 10 ans en qualité de juriste d’entreprise et de consultante au sein de grands groupes d’hospitalisation privée, Me DIALLO-MISSOFFE prête serment au barreau de Paris en avril 2014.
          <br/><br/>
          Lors de sa formation au sein de la Haute Ecole des Avocats Conseil (HEDAC) de Versailles, Me&nbsp;DIALLO-MISSOFFE a pu enrichir ses compétences en droit pénal et droit pénal des affaires au sein de cabinets parisiens réputés et reconnus (Allen & Overy, Saint Louis Avocats).
          <br/><br/>
          Très engagée dans la défense des plus démunis, elle développe également une expertise certaine en droit pénal et en droit des étrangers grâce à son intervention au sein des associations ARAPEJ Numéro vert (informations juridiques aux personnes détenues sur l’ensemble territoire), Droits d’Urgence, ou encore le CGLPL (Contrôleur Général des Lieux de Privation de Liberté).
          <br/><br/>
          Forte de ces expériences, Me DIALLO-MISSOFFE crée rapidement son propre cabinet au sein du barreau de la Seine Saint Denis afin d’exercer son activité dans les domaines du droit pénal, droit des étrangers, droit de la famille et droit de la responsabilité médicale.
        </p>
      </Presentation>
    </CabinetStyled>
  )
}

export default Cabinet;
