import React from 'react';
import useLargeImages from '../hooks/useLargeImages';
import Img from "gatsby-image/withIEPolyfill"
import styled, { ThemeContext } from 'styled-components';
import { gatsbyImage, displayFlex } from '../styles/mixins';
import { setRgbaOpacity } from '../styles/theme';
import Logo from './logo';
import { media } from '../styles/mediaQueries';

const LandingStyled = styled.div`
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  ${media.desktop`
    margin-top: ${props => -props.theme.height.header}px;
  `}
  ${gatsbyImage({
    width: '100% !important',
    height: '100% !important',
  })}
`
const startColor = setRgbaOpacity('rgba(45, 82, 102, 1.00)', 0.57);
const middleColor = setRgbaOpacity('rgba(33, 58, 68, 1.00)', 0.77);
const endColor = setRgbaOpacity('rgba(15, 32, 40, 1.00)', 0.87);

const Filter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${endColor};  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top left, ${endColor}, ${middleColor}, ${startColor});  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to top left, ${endColor}, ${middleColor}, ${startColor}); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  ${displayFlex()}
  * {
    text-shadow: 1px 1px 25px rgba(15, 32, 40, 1.00);
    text-align: center;
  }
`

const Landing = props => {
  const landingImage = useLargeImages('landing')
  const { color: { white } } = React.useContext(ThemeContext);
  return(
    <LandingStyled>
      <Img
        fluid={landingImage}
        alt="Ville de Bobigny"
        title="Ville de Bobigny"
        />
      <Filter>
        <Logo
          forIe
          color={white}
          forTitle
        />
      </Filter>
    </LandingStyled>
  )
}

export default Landing;
