import styled from 'styled-components';

const Title = styled.h2`
  font-size: 1.75em;
  display: block;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  width: 100%;
  text-align: center;
  &::after {
    background-color: ${props => props.theme.color.defaultColor};
    content: "";
    height: 3px;
    width: 60px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export default Title;
