import React from 'react';
import styled from 'styled-components';
import { displayFlex } from '../styles/mixins';
import Title from './title';
import { HONORAIRES } from '../reference/anchors';
import { formatString } from '../helpers/selectors';
import { media } from '../styles/mediaQueries';

const HonorairesContainer = styled.div`
  ${displayFlex({
    flexDirection: 'column'
  })}
  padding: 2em 5em;
  ${media.desktop`
    padding: 100px 5em;
  `}
  max-width: 100vw;
  text-align: center;
  p {
    max-width: 650px;
  }
`

const Honoraires = props => {

  return(
    <HonorairesContainer id={formatString(HONORAIRES)}>
        <Title>Honoraires</Title>
        <p>Conformément aux règles déontologiques de la profession d’avocat, les honoraires du cabinet sont déterminés selon plusieurs critères et notamment la complexité de l’affaire, le temps consacré au dossier, et la situation personnelle du client.
        <br/>
        <br/>
        En fonction des situations et du choix du client, le montant des honoraires peut être forfaitaire ou facturé au temps passé, auquel peut s’ajouter un honoraire complémentaire de résultat.
        <br/>
        <br/>
        Les modalités d'intervention du cabinet font l'objet d'une convention d'honoraires, signée entre l'avocat et son client.</p>
    </HonorairesContainer>
  )
}

export default Honoraires;
