import { useStaticQuery, graphql } from "gatsby"
import { getImageFromSrc } from "../helpers/selectors";

const useImages = (source) => {
  const {
    allImageSharp:{edges: images },
  } = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

    if (source) return getImageFromSrc(images, source);
    return images;
}

export default useImages
