import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Landing from "../components/landing"
import Cabinet from "../components/cabinet"
import Competences from "../components/competences"
import Coordonnees from "../components/coordonnees"
import useWindowSize from "../hooks/useWindowSize"
import Contact from "../components/contact"
import Honoraires from "../components/honoraires"

const IndexPage = () => {

  const [obliqueAngle, saveObliqueAngle] = React.useState();
  const [imageHeight, saveImageHeight] = React.useState();
  const { width: windowWidth } = useWindowSize()

 return(
    <Layout>
      <SEO title='Diallo-Missoffe Avocat' />
      <Landing />
      <Cabinet
        windowWidth={windowWidth}
        saveObliqueAngle={saveObliqueAngle}
        saveImageHeight={saveImageHeight}
      />
      <Competences
        obliqueAngle={obliqueAngle}
      />
      <Honoraires />
      <Coordonnees
        imageHeight={imageHeight}
      />
      <Contact />
    </Layout>
 )
}

export default IndexPage
