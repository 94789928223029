import React from "react"
import styled, { css } from "styled-components"
import { COMPETENCES } from "../reference/anchors"
import { formatString } from "../helpers/selectors"
import { displayFlex } from "../styles/mixins"
import Title from "./title"
import { media } from "../styles/mediaQueries"

const competences = [
  {
    name: "Droit pénal",
    content:
      "Le cabinet intervient dans tous les domaines du droit pénal, tant pour les auteurs d’infractions que les victimes, et ce à tous les stades de la procédure pénale, notamment :",
    list: [
      "Durant l’audition libre",
      "Durant la garde à vue",
      "Lors du défèrement devant le juge d’instruction ",
      "Lors du débat devant le juge des libertés et de la détention (JLD) en cas de détention provisoire",
      "Durant l’information judiciaire devant le juge d’instruction (instruction en matière correctionnelle et criminelle)",
      "Lors du renvoi devant une juridiction pénale (Tribunal de police, Tribunal correctionnel, Cour d’assises)",
      "Dans le cadre d’une procédure simplifiée (composition pénale, ordonnance pénale, comparution sur reconnaissance préalable de culpabilité dite « CRPC »)",
      " Lors d’une demande d’aménagement de peines devant le juge de l’application des peines (JAP), le Tribunal de l’application des peines (TAP). En cas de recours devant la Chambre de l’application des peines de la Cour d’appel (CHAP)",
      "Lors d’une commission disciplinaire au sein d’un établissement pénitentiaire (droit pénitentiaire)",
      "Lors d’une requête en effacement B2 (casier judiciaire)",
    ],
    content2:
      "Le cabinet vous assiste devant l’ensemble des juridictions de la région parisienne ainsi que sur l’ensemble du territoire national.",
  },
  {
    name: "Droit des étrangers",
    content:
      "Le cabinet vous assiste et vous conseille dans le cadre votre démarche administrative, et notamment dans les domaines suivants :",
    list: [
      "Demande de visa",
      "Recours contre les rejets de délivrance de visa",
      "Demande de regroupement familial",
      "Recours contre les rejets de regroupement familial",
      "Demande de titre de séjour (1ère demande, changement de statut, admission exceptionnelle, carte pluriannuelle, carte de résident)",
      "Recours contre les rejets de titre de séjour",
      "Recours contre les obligations de quitter le territoire (OQTF)",
      "Recours contre les interdictions de retour sur le territoire français (IRTF)",
      "Demande de naturalisation",
      "Recours contre les rejets de naturalisation",
      "Demande d’asile devant l’OFPRA, ",
      "Recours contre les rejets d’asile devant la Cour Nationale du Droit d’Asile (CNDA).",
    ],
  },
  {
    name: "Droit de la famille",
    content:
      "Le cabinet vous accompagne et assure la défense de vos intérêts dans le cadre des procédures suivantes :",
    list: [
      "Divorce consentement mutuel / divorce contentieux",
      "Fixation et/ou modification des modalités d’exercice de l’autorité parentale (exercice conjoint ou exclusif de l’autorité parentale, droit de visite et d’hébergement, pension alimentaire, etc..)",
      "Contestation et/ou reconnaissance de paternité",
      "Ordonnance de protection (femmes victimes de violences conjugales)",
      "Adoptions",
      "Assistance éducative (procédure devant le juge pour enfants et placement de l’enfant à l’Aide Sociale à l’Enfance).",
    ],
    content2:
      "Le cabinet vous assiste devant l’ensemble des juridictions de la région parisienne ainsi que sur l’ensemble du territoire national.",
  },
  {
    name: "Droit de la responsabilité&nbsp;médicale",
    content: `Le cabinet conseille, accompagne et représente le plus tôt possible les victimes d’accidents ayant entrainé un préjudice corporel pour assurer la défense de leurs intérêts ainsi que ceux de leurs familles.<br/>
    Les domaines d’intervention concernent notamment :`,
    list: [
      "Les erreurs médicales",
      "Les accidents thérapeutiques",
      "Les accidents de la route",
      "Les accidents de la vie domestique",
      "Les accidents scolaires.",
    ],
    content2: `Le cabinet assiste les victimes et leurs familles tout au long de la procédure, qu’elle soit amiable (CRCI, fond d’indemnisation), ou contentieuse (devant la juridiction civile, pénale ou administrative).<br/><br/>
    Les victimes seront systématiquement assistées et conseillées lors de l’expertise médicale ainsi que lors de leur demande d’indemnisation des préjudices subis (corporel, moral, etc...).<br/><br/>
    Me DIALLO-MISSOFFE peut vous assister devant l’ensemble des juridictions judiciaires et administratives françaises. En vertu des dispositions régissant la multipostulation parisienne, elle peut directement postuler auprès des tribunaux de grande instance de Paris, Bobigny, Créteil et Nanterre, et par voie de conséquence auprès de la Cour d’appel de Paris et de Versailles.`,
  },
]

const CompetencesStyled = styled.div`
  padding: 2em 5em;
  max-width: 100vw;
  ${displayFlex({
    flexDirection: "column",
  })}
  ${media.desktop`
    justify-content: space-between;
    padding: 100px 5em;
  `}
  overflow: hidden;
  background: ${props => props.theme.color.secondaryBg};
`

const LeftSide = styled.div`
  flex-grow: 3;
  ${media.desktop`
    ${displayFlex({
      flexDirection: "column",
    })}
  `}
  p {
    text-align: center;
    max-width: 90vw;
    flex: 1;
  }
`

const RightSide = styled.div`
  flex-grow: 45;
  /* max-width: 750px; */
  ${displayFlex({
    flexDirection: "column",
  })}
  ${media.desktop`
    padding: 2em 2em 0;
    height: 100%;
  `}
`

const Sections = styled.div`
  ${displayFlex({
    justifyContent: "center",
    alignItems: "flex-start",
  })}
  max-width: 300px;
  flex-wrap: wrap;
  height: ${props =>
    props.theme.height.section * competences.length +
    props.theme.margin.bottom.section * (competences.length + 2)}px;
  ${media.desktop`
    max-width: 1000px;
    width: 100%;
    height: ${props => props.theme.height.section + 2}px;
    justify-content: space-between;
    border: 1px solid ${props => props.theme.color.primary};
    background: ${props => props.theme.color.secondaryBg};
    border-radius: ${props => props.theme.height.section - 20}px;
    overflow: hidden;
    ${props => props.forMobile && "display: none;"}
  `}
  ${props => props.forMobile && "margin-bottom: 30px;"}
`

const activeSession = css`
  background: ${props => props.theme.color.primary};
  color: ${props => props.theme.color.secondaryBg};
`

const Section = styled.button`
  cursor: pointer;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 220px;
  height: ${props => props.theme.height.section}px;
  color: ${props => props.theme.color.primary};
  border-radius: 50px;
  transition: all 250ms ease-in-out;
  outline: none;
  ${props => props.active && activeSession}
  ${media.desktop`
    :last-of-type {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      /* hack to prevent small gap on the right */
      margin-right: -1px;
    }
    :first-of-type {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  `}
`

const CompetenceContainer = styled.div`
  padding-top: 30px;
  width: 100%;
  position: relative;
  ${media.desktop`
    margin-top: unset;
    overflow: hidden;
    flex-grow: 1;
  `}
  display: flex;
`

const setVisible = css`
  opacity: 1;
  pointer-events: auto;
  ${displayFlex({
    justifyContent: "center",
  })}
`

const CompetenceSubContainer = styled.div`
  flex-basis: 100%;
  flex-shrink: 0;
  opacity: 0;
  display: none;
  transition: all 250ms ease-in-out;
  ${props => props.active && setVisible};
  ${({ activeIndex }) => media.desktop`
    ${setVisible}
    transition: all 500ms ease-in-out;
    transform: translateX(${-100 * activeIndex}%);
  `}
`

const Competence = styled.div`
  flex-basis: 100%;
  flex-shrink: 0;
  max-width: 80vw;
  text-align: left;
  align-items: flex-start !important;
  > * {
    display: inline-block;
    margin-bottom: 20px;
    max-width: 100%;
    text-align: left;
  }
  ${media.desktop`
    ${setVisible};
    flex-direction: column;
    max-width: 1000px;
  `}
`

const ListContainer = styled.ul`
  ${displayFlex({
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
  })}
  /* padding-left: 30px; */
  text-align: left;
  list-style: inside;
  li {
    max-width: 100%;
    text-align: left;
  }
`

const Competences = ({ obliqueAngle }) => {
  const [compToShowIndex, setCompToShow] = React.useState(0)

  const toScrollRef = React.useRef(null)
  const isInitialMount = React.useRef(true)

  React.useEffect(() => {
    // hack to scroll only on mobile
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      if (toScrollRef.current.getBoundingClientRect().width < 850) {
        toScrollRef.current.scrollIntoView()
      }
    }
  }, [compToShowIndex])

  const sections = (forMobile = false) => (
    <Sections forMobile={forMobile}>
      {competences.map((comp, ind) => (
        <Section
          key={comp.name}
          onClick={e => {
            e.preventDefault()
            setCompToShow(ind)
          }}
          active={compToShowIndex === ind}
          dangerouslySetInnerHTML={{ __html: comp.name }}
          // ref={ind === competences.length - 1 && !forMobile ? toScrollRef : null}
        />
      ))}
    </Sections>
  )

  return (
    <CompetencesStyled id={formatString(COMPETENCES)}>
      <LeftSide>
        <Title>Compétences</Title>
        <p>
          Pour chacune de ses interventions, Me&nbsp;DIALLO-MISSOFFE assurera un
          travail axé sur la confiance, la rigueur, la confidentialité et
          l’indépendance, éléments indispensables à une relation de confiance
          avec le client et à la mise en place d’une défense sur-mesure.
        </p>
      </LeftSide>
      {/* <Separator obliqueAngle={obliqueAngle} /> */}
      <RightSide>
        {sections()}
        <CompetenceContainer ref={toScrollRef}>
          {competences.map((comp, ind) => (
            <CompetenceSubContainer
              key={comp.name}
              active={compToShowIndex === ind}
              activeIndex={compToShowIndex}
            >
              <Competence active={compToShowIndex === ind}>
                <span dangerouslySetInnerHTML={{ __html: comp.content }} />
                {comp.list && (
                  <ListContainer>
                    {comp.list.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ListContainer>
                )}
                {comp.content2 && (
                  <span dangerouslySetInnerHTML={{ __html: comp.content2 }} />
                )}
              </Competence>
            </CompetenceSubContainer>
          ))}
        </CompetenceContainer>
        {sections(true)}
      </RightSide>
    </CompetencesStyled>
  )
}

export default Competences
